import request from './request.js';

export default {

  uploadList(_data) {
    //文件上传
    return request({
      url: '/system/upload/uploadList',
      method: 'post',
      data: _data,
    });
  },

  getToken() {
    //获取上传token
    return request({
      url: '/system/upload/getToken',
      method: 'get',
    });
  },

  authPasswordLogin(_data) {
    //登录接口
    return request({
      url: '/system/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },



  institutionsDetail(_data) {
    //基本信息
    return request({
      url: '/institutions/info/info?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  editPassword(_data) {
    //修改密码
    return request({
      url: '/institutions/info/editPassword?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },


  OrderList(_data) {
    //商品订单列表
    return request({
      url: '/system/order/initOrderPage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  initSonCoursePage(_data) {
    //商品订单列表
    return request({
      url: '/system/order/initSonCoursePage?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  authPasswordLogin(_data) {
    //登录接口
    return request({
      url: '/system/login/authPasswordLogin?data=' + JSON.stringify(_data),
      method: 'post',
    });
  },



  workbenchesinfo(_data) {
    //基本数据
    return request({
      url: '/system/workbenches/info?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

  lineChart(_data) {
    //折线图
    return request({
      url: '/system/workbenches/lineChart?data=' + JSON.stringify(_data),
      method: 'get',
    });
  },

 

};
